import { Injectable } from '@angular/core'
import { NgxIndexedDBService } from 'ngx-indexed-db'
import * as _ from 'lodash'

export const IndexDBConfig:any = {
	DBName:'search_subject_suggestion',
	DBSchema:{
		DBKey:'SubjectSuggestionText',
	},
	DBNameCustomTariff:'search_customtariff_suggestion',
	DBSchemaCustomTariff:{
		DBKey:'SuggestionText',
	}

}

@Injectable({
	providedIn: 'root',
})

export class IndexDBService {
	constructor(private dbService: NgxIndexedDBService) {}

	addSuggestion(text: string) {
		this.dbService.getByIndex('search_suggestion', 'SuggestionText', text).subscribe((existData: any) => {
			if (_.isEmpty(existData)) {
				this.dbService.add('search_suggestion', { SuggestionText: text }).subscribe((key) => {
					console.log('key: ', key)
				})
			}else{
		// Delete suggestion to re-add so that suggection can be moved to top
		this.updateSuggestion(text, true, existData);
			}
		})
	}

	getAllSuggestions() {
		return this.dbService.getAll('search_suggestion')
	}

	deleteSuggestion(key: string) {
		return this.dbService.deleteByKey('search_suggestion', key)
	}

	// getStartWithSuggestion(text: string) {
	// 	let suggestion: any = this.dbService.getAll('search_suggestion') || [];
	// 	if (suggestion.length) {
	// 		return suggestion.filter((data) => {
	// 			data.SuggestionText.startsWith(text);

	// 		})

	// 	} else {
	// 		return this.dbService.getAll('search_suggestion');
	// 	}
	// }  need to remove after final testing

	updateSuggestion(text: string, isDeleted: boolean, deletedData?: Object) {
		if(isDeleted){
this.deleteSuggestion(deletedData['id'])
.subscribe((deleted: any) => {
})
		}
				this.dbService.add('search_suggestion', { SuggestionText: text, }).subscribe((key) => {
					console.log('key update: ', key)
				})
	}

	addSubjectSuggestion(text: string) {
		this.dbService.getByIndex(IndexDBConfig.DBName, IndexDBConfig.DBSchema.DBKey , text).subscribe((existData: any) => {
			if (_.isEmpty(existData)) {
				this.dbService.add(IndexDBConfig.DBName, { [IndexDBConfig.DBSchema.DBKey]: text }).subscribe((key) => {
					console.log('key added here: ', key)
				})
			}else{
		// Delete suggestion to re-add so that suggection can be moved to top
		this.updateSubjectSuggestion(text, true, existData);
			}
		})
	}

	getSubjectSuggestion(text:string) {
		return this.dbService.getByIndex(IndexDBConfig.DBName, IndexDBConfig.DBSchema.DBKey , text)
;	}


	addAllSubjectSuggestions(text:string) {
		return this.dbService.add(IndexDBConfig.DBName, { [IndexDBConfig.DBSchema.DBKey]: text });
	}

	getAllSubjectSuggestions() {
		return this.dbService.getAll(IndexDBConfig.DBName)
	}

	deleteSubjectSuggestion(key: string) {
		return this.dbService.deleteByKey(IndexDBConfig.DBName, key)
	}

	updateSubjectSuggestion(text: string, isDeleted: boolean, deletedData?: Object) {
		if(isDeleted){
this.deleteSubjectSuggestion(deletedData['id'])
.subscribe((deleted: any) => {
})
		}
				this.dbService.add(IndexDBConfig.DBName, { [IndexDBConfig.DBSchema.DBKey]: text, }).subscribe((key) => {
					console.log('key update: ', key)
				})
	}

	addDBSuggestion(DBName:string, DBKey:string ,text: string) {
		this.dbService.getByIndex(DBName, DBKey , text).subscribe((existData: any) => {
			if (_.isEmpty(existData)) {
				this.dbService.add(DBName, { [DBKey]: text }).subscribe((key) => {
					console.log('key added here: ', key)
				})
			}else{
		// Delete suggestion to re-add so that suggection can be moved to top
		//this.updateDBSuggestion(DBName, DBey ,text, true, existData);
			}
		})
	}

	getDBSuggestion(DBName:string, DBey:string, text:string) {
		return this.dbService.getByIndex(DBName, DBey , text)
;	}


	addAllDBSuggestions(DBName:string, DBey:string, text:string) {
		return this.dbService.add(DBName, { [DBey]: text });
	}

	getAllDBSuggestions(DBName:string) {
		return this.dbService.getAll(DBName);
	}

	deleteDBSuggestion(DBName:string, key: string) {
		return this.dbService.deleteByKey(DBName, key)
	}

	updateDBSuggestion(DBName:string, DBey:string, text: string, isDeleted: boolean, deletedData?: Object) {
		if(isDeleted){
this.deleteDBSuggestion(DBName, deletedData['id'])
.subscribe((deleted: any) => {
})
		}
				this.dbService.add(DBName, { [DBey]: text, }).subscribe((key) => {
					console.log('key update: ', key)
				})
	}

}
