import { BrowserModule } from '@angular/platform-browser'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from '@App/app-routing.module'
import { AppComponent } from '@App/app.component'
import { AppUtilityService } from '@Utils/app-utlility.service'
import { AuthService } from '@Services/auth.service'
import { HomeService } from '@Services/home.service'
import { CentaxHeaderModule } from '@Modules/header/header.module'
import { CentaxFooterModule } from '@Modules/footer/footer.module'
import { environment } from '@Env/environment'
import { RippleModule } from 'primeng/ripple'
import { CentaxSidebarModule } from '@Modules/sidebar/sidebar.module'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { HttpCacheService } from '@Services/http-cache.service'
import { HttpRequestInterceptor } from '@Interceptors/centax-http.interceptor'

import { HttpHelperService } from '@Services/http-helper.service'
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db'
import { IndexDBConfig, IndexDBService } from './utility/indexdb.service'
// import { ServiceWorkerModule } from '@angular/service-worker';
// import { CentaxServiceWorker } from './utility/sw.service';
import { RightPanelService } from './modules/right-panel/services/right-panel.service'
import { CommonAlertService } from '@Services/common-alert.service'
import { MessageService } from 'primeng/api'
import { ToastModule } from 'primeng/toast'
import { CentaxSocialAuthService } from '@Utils/centax-social-auth.service'
import { FaceBookLoginConstants } from '@App/auth/facebook-login/constants/facebook.constant'
import { GoogleLoginConstants } from '@App/auth/google-login/constants/google.constant'
import {SocialAuthServiceConfig, FacebookLoginProvider, GoogleInitOptions} from '@abacritt/angularx-social-login'
import { GoogleLoginProvider } from '@abacritt/angularx-social-login'
import { MessagesModule } from 'primeng/messages'
import { MessageModule } from 'primeng/message'
import { ConfirmationService } from './utility/confirmation.service'
import { TermsAndConditionsComponent } from './miscellaneous/terms-and-conditions/terms-and-conditions.component'
import { CopyrightPolicyComponent } from './miscellaneous/copyright-policy/copyright-policy.component'
import { PrivacyPolicyComponent } from './miscellaneous/privacy-policy/privacy-policy.component'
import { ReturnPolicyComponent } from './miscellaneous/return-policy/return-policy.component'
import { ShippingPolicyComponent } from './miscellaneous/shipping-policy/shipping-policy.component'
import { DisclaimerComponent } from './miscellaneous/disclaimer/disclaimer.component'
import { AboutUsComponent } from './miscellaneous/about-us/about-us.component'
import { ContactUsComponent } from './miscellaneous/contact-us/contact-us.component'
import { JoyrideModule } from 'ngx-joyride'
import { StartupService } from './utility/app-start.service'
import { NgxPaginationModule } from 'ngx-pagination'

export function startupServiceFactory(startupService: StartupService): Function {
	return () => startupService.load()
}

const dbConfig: DBConfig = {
	name: 'CentaxDB',
	version: 4,
	objectStoresMeta: [
		{
			store: 'search_suggestion',
			storeConfig: {
				keyPath: 'id',
				autoIncrement: true,
			},
			storeSchema: [{ name: 'SuggestionText', keypath: 'SuggestionText', options: { unique: true } }],
		},
		{
			store: 'upload_and_search',
			storeConfig: {
				keyPath: 'id',
				autoIncrement: true,
			},
			storeSchema: [{ name: 'data', keypath: 'data', options: { unique: true } }],
		},
		{
			store: IndexDBConfig.DBName,
			storeConfig: {
				keyPath: 'id',
				autoIncrement: true,
			},
			storeSchema: [{ name: IndexDBConfig.DBSchema.DBKey , keypath: IndexDBConfig.DBSchema.DBKey, options: { unique: true } }],
		},
		{
			store: IndexDBConfig.DBNameCustomTariff,
			storeConfig: {
				keyPath: 'id',
				autoIncrement: true,
			},
			storeSchema: [{ name: IndexDBConfig.DBSchemaCustomTariff.DBKey , keypath: IndexDBConfig.DBSchemaCustomTariff.DBKey, options: { unique: true } }],
		},
	],
}

const googleLoginOptions: GoogleInitOptions = {
	oneTapEnabled: false, // default is true
};

@NgModule({
	declarations: [
		AppComponent,
		TermsAndConditionsComponent,
		CopyrightPolicyComponent,
		PrivacyPolicyComponent,
		ReturnPolicyComponent,
		ShippingPolicyComponent,
		DisclaimerComponent,
		AboutUsComponent,
		ContactUsComponent,
	],
	imports: [
		AppRoutingModule,
		BrowserModule,
		HttpClientModule,
		BrowserAnimationsModule,
		CentaxHeaderModule,
		CentaxFooterModule,
		CentaxSidebarModule,
		RippleModule,
		MessagesModule,
		MessageModule,
		NgxIndexedDBModule.forRoot(dbConfig),
		// ServiceWorkerModule.register('ngsw-worker.js', {
		// enabled: false, // environment.production,
		// Register the ServiceWorker as soon as the application is stable
		// or after 30 seconds (whichever comes first).
		// registrationStrategy: 'registerWhenStable:30000'
		// }),
		ToastModule,
		JoyrideModule.forRoot(),
		NgxPaginationModule,
	],
	providers: [
		AppUtilityService,
		IndexDBService,
		AuthService,
		HomeService,
		HttpCacheService,
		HttpHelperService,
		// CentaxServiceWorker,
		CommonAlertService,
		MessageService,
		RightPanelService,
		ConfirmationService,
		CentaxSocialAuthService,
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: true,
				providers: [
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(GoogleLoginConstants.APP_ID,	googleLoginOptions),
					},
					{
						id: FacebookLoginProvider.PROVIDER_ID,
						provider: new FacebookLoginProvider(FaceBookLoginConstants.APP_ID, googleLoginOptions),
					},
				],
			} as SocialAuthServiceConfig,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpRequestInterceptor,
			multi: true,
		},
		StartupService,
		{
			// Provider for APP_INITIALIZER
			provide: APP_INITIALIZER,
			useFactory: startupServiceFactory,
			deps: [StartupService],
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
