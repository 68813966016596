import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ApiStatus, CentaxAppModuleList, CheckoutFrom } from 'src/app/constant/centax-app.constant'
import { APIResponse, HeaderModule } from 'src/app/interface/centax-data.interface'
import { HomeService } from 'src/app/services/home.service'
import { MenuItem, MessageService, PrimeNGConfig } from 'primeng/api'
import { AppUtilityService } from 'src/app/utility/app-utlility.service'
import * as _ from 'lodash'
import { LocalStorageService } from '@App/services/localstorage.service'
import { LocalStorageDataModel } from '@App/constant/localstorage.constant'
import { LocalStorageTypes } from '@App/interface/localstorage-types'
import { CentaxSocialAuthService } from '@Utils/centax-social-auth.service'
import { HeaderService } from '@Modules/header/services/header.service'
import { UserService } from '@App/user/services/user.service'
import * as moment from 'moment'
import { BaseService } from '../component-modules/base/base.service'
import { Subject, Subscription, takeWhile } from 'rxjs'
import { AuthService } from '@Services/auth.service'
import { JoyrideService } from 'ngx-joyride'
import { HeaderDropDownPageChange } from '@App/constant/constant'
import { CommonAlertService } from '@App/services/common-alert.service'
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { NETCORE_EVENT_LIST } from '@App/constant/netcore.eventList'
import { PricingService } from '@App/pricing/pricing.service'
declare const $: any
@Component({
	selector: 'centax-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	providers: [MessageService, UserService],
})
export class HeaderComponent implements OnInit {
	showGlobalSearch: boolean = true
	showSearch: boolean = true
	visibleSidebar1
	visibleSidebar2
	displayModal: boolean
	displayBasic: boolean
	activeItems = 'AP'
	isExcusSearch: boolean = false
	arrowkeyLocation: number = -1

	moduleList: HeaderModule[] = CentaxAppModuleList
	onlyTaxmannLogo: boolean = false
	activeModule: HeaderModule
	search: string = '';
	searchSubRef = new Subject();

	isBrowserAlertActive: boolean = false;
	showBrowserAlert: boolean = false;
	showPopupHeader: boolean = false;
	isNoThanks: boolean = false;
	OneSignal: any;
	searchSuggestion: any[]
	searchRecommendation: any[]
	showSearchSuggestions: boolean = false
	showMinimumThreeCharactersMsg: boolean = false;

	userDetails
	isLoggedIn: boolean = this.appUtilityService.isLoggedIn()
	dateFrom = ''
	dateTo = ''
	AdvModuleList: any[] = []
	AdvDocumentType: any[] = []
	exactphraseText = ''
	anyOfTheseText = ''
	noneOfTheseText = '';
	allOfTheseText = '';
	categoryList: any[] = []
	groupList: any[] = []
	selectedSubjectArr: any[] = []
	selectedSubjectArrList: any[] = []
	selectedSubjectValue: any[] = []
	centaxSubjectListData: any[] = []
	showSubjectEdit: boolean = true
	today = new Date()
	showGlobalSearch$: Subscription = new Subscription()
	showSearchSuggestion$: Subscription = new Subscription()
	AdvFilterReset$: Subscription = new Subscription()
	showMobileSearchMenu: boolean = false
	showUploadAndSearchMenu: boolean = false
	hideAdvanceSearchIcon: boolean = false
	uploadedFileName: string = ''
	isAdvanceSearchCalled = false
	searchBoxFocussed: boolean = false
	isSideSuggetion: boolean = false
	sideBarPosition = '0px'
	openForTut: boolean = false
	isShowPricing: boolean = true
	showPagechangeValue
	headerDropDownPageChange = HeaderDropDownPageChange
	alive: boolean = true
	email: string = ''
	userName: string = ''
	public netCorePayload = {};
	public utmObj = {};
	showResearchRenewalNotificationPopup: boolean = false;
	isShowResearchRenewalStip: boolean = false;
	researchRenewalData:any = [];
     researchRenewalNotificationPopupData = [
		{days:1},
		{days:7},
		{days:15},
	];
	calculatedResearchRenewalNotificationPopupData = [];
	noOfDaysForExpiryLeft:number;
	@Output() researchRenewalStipEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
	selectResearchRenewalNotificationPopupDataForStrip:any = [];
	noOfDaysSelected: number = 1;

	constructor(
		protected _homeService: HomeService,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected formBuilder: FormBuilder,
		protected appUtilityService: AppUtilityService,
		protected messageService: MessageService,
		protected primengConfig: PrimeNGConfig,
		protected centaxSocialAuthService: CentaxSocialAuthService,
		protected headerService: HeaderService,
		protected userService: UserService,
		protected baseService: BaseService,
		protected authService: AuthService,
		protected readonly joyrideService: JoyrideService,
		protected commonAlertService: CommonAlertService,
		protected pricingService: PricingService
	) {
		this.showGlobalSearch$ = this.appUtilityService.getShowGlobalSearchObs().subscribe((flag: boolean) => {
			this.showGlobalSearch = flag
		})
	}

	 menuActiveItems() {
		let menu = this.appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.SEARCH_MENU)
		if (menu && menu == 'AP') {
			this.activeItems = 'AP'
			LocalStorageService.setItem(
				LocalStorageTypes.LOCAL,
				LocalStorageDataModel.LAST_VISITED_URL_BEFORE_ALL_PAGE_SEARCH,
				window.location.href
			)
		} else if (menu && menu == 'ES') {
			this.activeItems = 'ES'
			LocalStorageService.setItem(
				LocalStorageTypes.LOCAL,
				LocalStorageDataModel.LAST_VISITED_URL_BEFORE_ALL_PAGE_SEARCH,
				window.location.href
			)
		}
		 this.activeItems || 'AP'
	}

	ngOnInit(): void {

	//	this.initOnesignal();
		this.utmObj = this.appUtilityService.createUtmObj();
		this.checkLogin()
		this.AdvFilterReset$ = this.appUtilityService.getAdvSearchReset().subscribe((res) => {
			if (res == 'all') {
				this.resetAdvSearchFilters()
			} else if (res == 'base') {
				this.dateFrom = ''
				this.dateTo = ''
				this.categoryList = []
				this.groupList = []
				this.selectedSubjectArrList = []
				this.selectedSubjectValue = []
				this.selectedSubjectArr = []
			}
		})
		this.showSearchSuggestion$ = this.appUtilityService.getHideSearchSuggestion().subscribe((res) => {
			this.showSearchSuggestions = res
		})
		this.primengConfig.ripple = true
		this._homeService.onTaxmannLogoVisible.subscribe((flag: boolean) => {
			this.onlyTaxmannLogo = flag || false
		})
		// set search text value to input model
		setTimeout(() => {
			this.search = (document.getElementById('header-search') as any)?.value
		}, 1500)

		if (this.isLoggedIn) {
			this.getUserInfo()
			this.callRenewalAlert();
		}
		this.personalDataSubscription()
		this.getAdvanceSearchIconStatus()
		this.getDocumentSearchDetails()
		//	this.setSearchTextHeaderSubject();

		this.activatedRoute.queryParams.subscribe((queryParams: any) => {
			// clear old selctions
			// it is clearing search text
          // this.search = '';
		  // this.exactphraseText = '';
		  // this.noneOfTheseText = '';
		   // this.anyOfTheseText = '';
			if (queryParams?.itemType == 'advanceSearch') {
			//	this.appUtilityService.isExcusSearch = false;
			console.log("quryparams", queryParams)
				if (queryParams['searchData']) {
					// if advance search item type and searchdata found mark it as
					let pattern = /"(.*?)/;
					if( queryParams['searchData'].match(pattern) ){
						//  includes double quotes, mark it as excus search
						this.appUtilityService.isExcusSearch = true;
						this.isExcusSearch = true;
					}else{
					}
					this.allOfTheseText = queryParams['searchData']
				}
				// if (queryParams['itemType']) {
				// 	this.activeItems = queryParams['itemType']
				// }
				if (queryParams['exactPhrase']) {
					this.appUtilityService.isExcusSearch = true;
					this.isExcusSearch = true;
					this.exactphraseText = queryParams['exactPhrase']
				}
				if (queryParams['noneOfTheseText']) {
					this.noneOfTheseText = queryParams['noneOfTheseText']
				}
				if (queryParams['anyOfTheseText']) {
					this.anyOfTheseText = queryParams['anyOfTheseText']
				}
				console.log("query params changes on call advance searchs", queryParams)
				//this.callAdvanceSearch(true)
			 }
			else  if (queryParams['itemType']) {
				this.activeItems = queryParams['itemType'];
				if(queryParams['itemType'] == "ES"){
					this.isExcusSearch = true;
					this.appUtilityService.isExcusSearch = true;
				}
			}

		})
		this.onPricingSubscription()
		this.onDropdownPageChange()
		this.searchSubRef.pipe(debounceTime(200), distinctUntilChanged()).subscribe((query: any) => {
			this.searchSuggest()
		});
		this.menuActiveItems();
	}

	ngAfterViewInit() {
		if (this.isLoggedIn) {
			let url = window.location.pathname
			if (url.includes('/latest-news-updates/all')) {
				if (
					localStorage.getItem(LocalStorageDataModel.FIRST_TIME_VISIT) == null ||
					localStorage.getItem(LocalStorageDataModel.FIRST_TIME_VISIT) == undefined
				) {
					localStorage.setItem(LocalStorageDataModel.FIRST_TIME_VISIT, 'true')
					this.startTour()
				} else {
					localStorage.setItem(LocalStorageDataModel.FIRST_TIME_VISIT, 'false')
				}
			}
		}
	}

	initOnesignal() {
 			this.OneSignal = window['OneSignal'] || [];

			this.OneSignal.push(["init", {
				appId: this.appUtilityService.getOneSignal() ,
				autoRegister: false,
				welcomeNotification: {
					disable: false,
				},
				persistNotification: false,
				notifyButton: {
					enable: false,
					prenotify: true,
					position: 'bottom-right',
					size: 'medium',
					showCredit: false
				},
				autoPrompt:false
			}]);
			this.showNotificationAlert(true);


	}

	getAdvanceSearchIconStatus(): void {
		this.appUtilityService.getHideAdvanceSearchIcon().subscribe((state: boolean) => {
			this.hideAdvanceSearchIcon = state
		})
	}

	getDocumentSearchDetails(): void {
		this.appUtilityService.getFileUploadSearchResult().subscribe((res: any) => {
			if (res) {
				this.uploadedFileName = res?.fileName
			}
		})
	}

	getUserInfo(): void {
		this.appUtilityService.showLoader = true
		const params = {
			type: 'personal',
		}
		this.headerService.getUserInfo(params).subscribe(
			(response: APIResponse) => {
				if (response.ResponseType == ApiStatus.SUCCESS) {
					this.appUtilityService.showLoader = false
					this.userDetails = response?.Data?.personalInfo
					this.appUtilityService.setDataOnLocalStorage(
						LocalStorageDataModel.USER_NAME,
						this.userDetails.FName
					)
					this.appUtilityService.setDataOnLocalStorage(
						LocalStorageDataModel.USER_LNAME,
						this.userDetails.LName
					)
					this.appUtilityService.setDataOnLocalStorage(
						LocalStorageDataModel.USER_EMAIL,
						this.userDetails.EmailID
					)
					this.appUtilityService.setDataOnLocalStorage(
						LocalStorageDataModel.USER_MOBILE,
						this.userDetails.PhoneNo
					)
				} else {
					this.appUtilityService.showLoader = false
				}
			},
			(error) => {
				this.appUtilityService.showLoader = false
			}
		)
	}

	personalDataSubscription(): void {
		this.userService.personalInfoDetails.subscribe((response: any) => {
			this.userDetails = response
		})
	}

	update() {
		this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Data Updated' })
	}

	delete() {
		this.messageService.add({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted' })
	}

	selectText() {
		if (this.search != '') {
			const input: any = document.getElementById('header-search')
			input.focus()
			input.select()
		}
	}

	onFocusSearch() {
		this.selectText()
		this.searchBoxFocussed = true
		if (this.showSearchSuggestions) {
			return
		}
		this.getHistory(true)
	}

	getHistory(isMatch: boolean = false) {
		this.appUtilityService
			.getDbInstance()
			.getAllSuggestions()
			.subscribe((result) => {
				this.showSearchSuggestions = true
				this.getMatchedResult(result, isMatch)
			})
	}
	getMatchedResult(result, isMatch) {
		if (this.search.length == 0) {
			this.searchSuggestion = result.slice(-5)
			this.searchSuggestion = this.searchSuggestion.reverse()
		} else {
			if (isMatch) {
				let response = []
				result.forEach((res: any) => {
					// let strArr = this.search.trim().split(' ')
					let strArr = this.search
					// for (let str of strArr) {
					let flag = res.SuggestionText.toLowerCase().startsWith(strArr.toLowerCase())
					if (flag) {
						response.push(res)
						// }
					}
				})

				this.searchSuggestion = response.slice(-5)
				this.searchSuggestion = _.uniqBy(this.searchSuggestion, 'id')
				if (this.searchSuggestion.length) {
					// single last matched record
					this.searchSuggestion = [this.searchSuggestion.reverse()[0]]
				} else {
					this.searchSuggestion = []
				}
			} else {
				this.searchSuggestion = result.slice(-5)
				this.searchSuggestion = this.searchSuggestion.reverse()
			}
		}
	}

	// onLogoClick() {
	// 	if (!this.activeModuleList) {
	// 		return;
	// 	}
	// 	this._homeService.showHeader.next(true);
	// 	this._homeService.showSidebarPage.next(true);
	// 	const url = this.router.serializeUrl(
	// 		this.router.createUrlTree([`${this.activeModuleList?.url}`])
	// 	);
	// 	if (this.activeModuleList?.url) {
	// 		if (this.activeModuleList?.type == "external") {
	// 			if (this.activeModuleList?.sameTab) {
	// 				window.open(url, '_self');
	// 			} else {
	// 				window.open(url, '_blank');
	// 			}
	// 		} else {
	// 			if (this.activeModuleList?.sameTab) {
	// 				if (this.activeModuleList.url.includes('latest-news-updates') || this.activeModuleList.url.includes('/')) {
	// 					this._homeService.sideBarCollapse.next(false);
	// 				}
	// 				this.router.navigate([this.activeModuleList?.url]);
	// 			} else {
	// 				window.open(url, '_blank');
	// 			}
	// 		}
	// 	}
	// }

	onLogoClick() {
		if (this.router.url.includes('latest-news-updates')) {
			this.router.navigate(['/']).then(() => {
				// window.location.reload()
			})
		} else {
			this._homeService.sideBarCollapse.next(true)
			this.router.navigate(['/'])
		}
	}

	onModuleItemClick(module: HeaderModule) {
		this.makeActiveModuleList(module.id)
		this.onLogoClick()
	}

	makeActiveModuleList(moduleName: string) {
		for (let module of this.moduleList) {
			if (module.id == moduleName) {
				module.active = true
			} else {
				module.active = false
			}
		}
	}

	onSearchFieldChange(query: string) {
		this.searchSubRef.next(query);
	}

	onKeyPressSearch(event: KeyboardEvent) {
		if (event && event?.key == 'Escape') {
			this.showSearchSuggestions = false;
		}
	}

	searchSuggest() {

		if (!this.search) {
			this.showSearchSuggestions = false
		}
		if (this.search) {
			this.getHistory(true)
		} else {
			this.getHistory(false)
		}

		if (this.search.trim().length > 2) {
			this._homeService.getSearchSuggestions(this.search.trim().slice(0,125)).subscribe((response: any) => {
				this.showSearchSuggestions = true
				if (response.ResponseType === ApiStatus.SUCCESS) {
					// if(!this.isSideSuggetion) {
					this.isSideSuggetion = response?.Data?.isNewKeyword
					// }
					this.searchRecommendation = response?.Data?.suggestions || []
					console.log('this.searchRecommendation====>', this.searchRecommendation)
				}
				// if (this.searchRecommendation.length == 0) {
				// 	this.searchRecommendation = [{ "SuggestionText": "No result found." }]
				// }
			})
		} else {
			this.searchRecommendation = []
		}

	}

	keyDown(event: KeyboardEvent) {
		let decisionBreak = (this.searchRecommendation?.length || 0) + (this.searchSuggestion?.length || 0)
		switch (event.key) {
			case 'ArrowDown':
				if (this.arrowkeyLocation < decisionBreak - 1) {
					this.arrowkeyLocation++
					this._foucsOnActiveIndex()
				}
				break
			case 'ArrowUp':
				if (this.arrowkeyLocation > 0) {
					this.arrowkeyLocation--
					this._foucsOnActiveIndex()
				}
				break
			case 'Enter':
				if (this.arrowkeyLocation >= 0) {
					this.search = document.getElementById(`li-${this.arrowkeyLocation}`)?.innerText
					this.navigateWithSearchSuggestionsOnEnter()
					this.arrowkeyLocation = -1
				} else {
					this.navigateWithSearchSuggestionsOnEnter()
					this.arrowkeyLocation = -1
				}
		}

		if (this.isSideSuggetion) {
			let splitTextArr = this.search.split(' ')
			let split = splitTextArr.splice(0, splitTextArr.length - 1).join(' ')
			this.sideBarPosition = split.length * 8.7 + 'px'
		}
	}

	private _foucsOnActiveIndex() {
		let activeSelection = document.getElementsByClassName('suggestion-list li-active')
		if (this.arrowkeyLocation > 0 && activeSelection && activeSelection[0]) {
			; (activeSelection[0] as HTMLElement).focus()
		}
		document.getElementById('li-' + this.arrowkeyLocation).scrollIntoView()
	}

	navigateWithSearchSuggestionsOnEnter(forcedSearchType?:string) {
		if(this.search.trim().length<3){
			this.showSearchSuggestions = false;
			this.showMinimumThreeCharactersMsg=true;
			setTimeout(()=>this.showMinimumThreeCharactersMsg=false,3500);
			return ;
		}
		if(this.search.trim().length>200){
			this.commonAlertService.successToast('Please limit your search to 200 characters or less. Search query parameters will be executed on the first 200 characters of search query including space')
		}
		let searchString=this.search.trim().slice(0,200);
		// remove old storage first
		this.appUtilityService.setDataOnLocalStorage('advanceSearchCall', 'N');
											// clear old selections if not advance search
											this.exactphraseText = '';
											this.anyOfTheseText = '';
											this.noneOfTheseText = '';
											this.allOfTheseText = '';
		this.netCorePayload = {

			email: this.appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.USER_EMAIL),
			searchQuery : searchString?.trim()
		}
		this.netCorePayload = {...this.netCorePayload,...this.utmObj}
		this.appUtilityService.netCoreEventTrack(NETCORE_EVENT_LIST.SEARCH_PERFORMED,this.netCorePayload)
		const input: any = document.getElementById('header-search')
		input.blur()
		searchString = searchString?.trim();
		// move to old selection of excus search
		this.isExcusSearch = false;
		//if (
		//	this.search?.length == 0 &&
		//	this.exactphraseText?.length == 0 &&
		//	this.noneOfTheseText?.length == 0 &&
		//	this.anyOfTheseText?.length == 0
	//	) {
			//this.router.navigate(['/latest-news-updates'])
	//	}
		if (searchString) {
			this.searchBoxFocussed = false
			this.isSideSuggetion = false
			this.sideBarPosition = '0px'
			this.showSearchSuggestions = false

			// this.searchBoxFocussed = false;
			this.showSearchSuggestions = false
			this.appUtilityService.getDbInstance().addSuggestion(searchString);
			if (this.activeItems == 'AP' || this.activeItems == 'ES') {
				let itemType = 'AP'
				if (this.activeItems == 'ES') {
					this.isExcusSearch = true;
					this.appUtilityService.isExcusSearch = true
					itemType = 'ES'
				}else{
					this.isExcusSearch = false;
					this.appUtilityService.isExcusSearch = false
					itemType = 'AP'
																															// clear old selections if not advance search
																															this.exactphraseText = '';
																															this.anyOfTheseText = '';
																															this.noneOfTheseText = '';
																															this.allOfTheseText = '';
				}
				if(searchString.trim().length >2){
				let pattern = /"(.*?)"/;
				let matches = searchString.match(pattern);
				if(forcedSearchType == "AP" || forcedSearchType == "ES" ){
					// do nothing for phrase search, if user forced search type to all pages
					if(forcedSearchType == "AP"){
					this.activeItems = 'AP';
					itemType = 'AP';
					this.isExcusSearch = false;
					this.appUtilityService.isExcusSearch = false
																				// clear old selections if not advance search
															this.exactphraseText = '';
															this.anyOfTheseText = '';
															this.noneOfTheseText = '';
															this.allOfTheseText = '';

}else{
this.activeItems = 'ES';
itemType = 'ES';
this.isExcusSearch = true;
this.appUtilityService.isExcusSearch = true;
}
				}
			 if(matches){
				//	this.search = this.search.replace(/^"(.*)"$/, '$1');
					let searchFor = searchString;
					let dataToSearch = searchFor.split('"');
					console.log("split data based on quotes", searchFor.split('""'));

					this.exactphraseText =  dataToSearch[1].trim() ;
					if((dataToSearch[2] && dataToSearch[2].length) || (dataToSearch[0] && dataToSearch[0].length) ){
						// decides for none of these text or text entered to taken as normal
						// pattern for + symbol
						console.log("data to search", dataToSearch)
						let pattern1 = /[\+]/;
						let pattern2 = /"(.*?)/;
						if( dataToSearch[2] && dataToSearch[2].length && ( dataToSearch[2].match(pattern2) || dataToSearch[2].match(pattern1) )){
							console.log("macthec", dataToSearch[2])
						}else if(dataToSearch[3] ){
							console.log("dataToSearch", dataToSearch[3])
						}else{
							if(dataToSearch[2] && dataToSearch[2].length && dataToSearch[2].includes("-") ){
					this.noneOfTheseText = dataToSearch[2].replace(/-/g,"").trim();
							}else{
								this.allOfTheseText = dataToSearch[2].trim();
							}
					}
					if(dataToSearch[0] && dataToSearch[0].length){
					if(searchString.includes( '"' + dataToSearch[0] + '"')){

					}else{
					this.allOfTheseText = dataToSearch[0].trim();
					}
				}
					let queryParams = {
						itemType: 'advanceSearch' ,
					};
					if( this.noneOfTheseText || this.anyOfTheseText || this.allOfTheseText){
						this.appUtilityService.setDataOnLocalStorage('advanceSearchCall', 'Y')
					itemType = 'advanceSearch';
					this.isAdvanceSearchCalled = true;
					this.isExcusSearch = true;
					this.appUtilityService.isExcusSearch = true;
					if (this.exactphraseText) {
						 queryParams['exactPhrase'] = '"' +  this.exactphraseText + '"'
						//queryParams['exactPhrase'] = this.exactphraseText
					}
					if (this.noneOfTheseText) {
						queryParams['noneOfTheseText'] = this.noneOfTheseText
					}
					if (this.anyOfTheseText) {
						queryParams['anyOfTheseText'] = this.anyOfTheseText
					}
					if (this.allOfTheseText) {
						queryParams['searchData'] = this.allOfTheseText
					}
					queryParams['activeItem']= itemType
						//this.search = '';
						this.router.navigate(['/search'], { queryParams })
						return;
					}else{
						this.isExcusSearch = false;
						this.appUtilityService.isExcusSearch = false;
																										// clear old selections if not advance search
																										this.exactphraseText = '';
																										this.anyOfTheseText = '';
																										this.noneOfTheseText = '';
																										this.allOfTheseText = '';
					}

					}

					//itemType = 'ES';
				let isAdanceSearchCall	 = this.appUtilityService.getDataFromLocalStorage('advanceSearchCall');
				if(isAdanceSearchCall && isAdanceSearchCall == 'Y'){
				}else{
					// this.isExcusSearch = true;
					// this.appUtilityService.isExcusSearch = true
				}
					//}
				}
			}
				// this.activatedRoute.queryParams.subscribe((queryParams: any) => {
				// 	if (this.search) {
				// 		if (queryParams?.documentSearch) {
				// 			this.router.navigate(['/search'], { queryParams: { searchData: this.search, documentSearch: queryParams?.documentSearch, itemType } });
				// 		} else {
				//
				// 			this.router.navigate(['/search'], { queryParams: { searchData: this.search, itemType } });
				// 		}
				// 	}

				// });
															// clear old selections if not advance search
															this.exactphraseText = '';
															this.anyOfTheseText = '';
															this.noneOfTheseText = '';
															this.allOfTheseText = searchString;
				console.log("searching coommong end routing")
				this.router.navigate(['/search'], { queryParams: { searchData: this.allOfTheseText, itemType } })
			}
		} else if(this.exactphraseText || this.anyOfTheseText || this.noneOfTheseText){
			let queryParams = {
				itemType: 'advanceSearch' ,
			};
			if( this.noneOfTheseText || this.anyOfTheseText){
				this.appUtilityService.setDataOnLocalStorage('advanceSearchCall', 'Y')
		   let	itemType = 'advanceSearch';
			this.isAdvanceSearchCalled = true;
			if (this.exactphraseText) {
				 queryParams['exactPhrase'] = '"' +  this.exactphraseText + '"'
				 this.isExcusSearch = true;
				 this.appUtilityService.isExcusSearch = true;
			}
			if (this.noneOfTheseText) {
				queryParams['noneOfTheseText'] = this.noneOfTheseText
			}
			if (this.anyOfTheseText) {
				queryParams['anyOfTheseText'] = this.anyOfTheseText
			}
			if(this.allOfTheseText){
				queryParams['searchData'] = this.allOfTheseText;
			}
			queryParams['activeItem']= itemType
				this.router.navigate(['/search'], { queryParams })
				return;
			}
		}
	}

	removeHistorySuggestion(event: any, key: string) {
		event.preventDefault()
		this.appUtilityService
			.getDbInstance()
			.deleteSuggestion(key)
			.subscribe((deleted: any) => {
				if (deleted) {
					this.getHistory(true)
				}
			})
	}

	navigateWithSearchSuggestions(suggestion: any) {
		this.showSearchSuggestions = false
		let newSearch = suggestion?.text || suggestion?.SuggestionText
		if (this.isSideSuggetion) {
			let splitTextArr = this.search.split(' ')
			let split = splitTextArr.splice(0, splitTextArr.length - 1).join(' ')
			this.search = split + ' ' + newSearch
		} else {
			this.search = newSearch
		}
		this.navigateWithSearchSuggestionsOnEnter()
		// this.appUtilityService.getDbInstance().addSuggestion(suggestion?.SuggestionText);
		// if (this.activeItems == 'All Page') {
		// 	// this.router.navigate(['/search'], { queryParams: { searchData: suggestion?.SuggestionText } });
		// } else {
		// 	// this._homeService.searchTextForCurrentPage.next(suggestion?.SuggestionText);
		// 	this.router.navigate([], {
		// 		queryParams: { searchData: suggestion?.SuggestionText },
		// 		queryParamsHandling: 'merge'
		// 	});
		// }
	}

	get inactiveModuleList(): HeaderModule[] {
		return this.moduleList.filter((module) => module.active === false)
	}

	get activeModuleList(): HeaderModule {
		return this.moduleList.filter((module) => module.active === true)[0]
	}

	get isMobileView() {
		return this.appUtilityService.isMobileView()
	}

	showBasicDialog() {
		this.displayBasic = true
		if (this.AdvModuleList.length == 0) {
			this.callAdvanceSearch(false)
		}
	}
	showModalDialog() {
		this.displayModal = true
	}

	logout(): void {
		this.netCorePayload = {
			pageUrl: window.location.href,
			firstName: this.appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.USER_NAME),
			lastName: this.appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.USER_LNAME),
			email: this.appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.USER_EMAIL)
		}
		this.netCorePayload = { ...this.netCorePayload, ...this.utmObj }
		this.appUtilityService.netCoreEventTrack(NETCORE_EVENT_LIST.LOGOUT, this.netCorePayload)
		this.authService.logout()
	}

	clearSearch() {
		this.search = ''
		setTimeout(() => {
			; (document.getElementById('header-search') as any).value = this.search
		}, 50)

		this.exactphraseText = ''
		this.noneOfTheseText = ''
		this.anyOfTheseText = ''
		this.allOfTheseText = '';
		let currentUrl = this.router.url
		if (currentUrl.includes('search')) {
			this.router.navigate(['/'])
			return
		}

		let lastVisitedURL = LocalStorageService.getItem(
			LocalStorageTypes.LOCAL,
			LocalStorageDataModel.LAST_VISITED_URL_BEFORE_ALL_PAGE_SEARCH
		)
		// if (this.activeItems == 'Current Page' && lastVisitedURL.indexOf('search') >= 0) {
		// 	this.router.navigate([]);
		// } else {
		// }
		this.activatedRoute.queryParams.subscribe((queryParams: any) => {
			if (queryParams?.documentSearch) {
				this.router.navigate(['/search'], { queryParams: { documentSearch: queryParams?.documentSearch } })
			} else {
				if (this.search == '') this.router.navigate([])
			}
		})
	}

	callAdvanceSearch(navigationFlag = true) {
	//	this.appUtilityService.exactPhraseFilterText = this.exactphraseText
		// this.search = this.baseService.searchTextFromHeader;
		if (!this.allOfTheseText && !this.exactphraseText && !this.anyOfTheseText && !this.noneOfTheseText && navigationFlag) {
			this.closeAdvancedFilters()
			return
		} else {
			let dateFrom = ''
			let dateTo = ''
			if (_.isDate(this.dateFrom)) {
				dateFrom = moment(this.dateFrom).format('YYYYMMDD')
			}
			if (_.isDate(this.dateTo)) {
				dateTo = moment(this.dateTo).format('YYYYMMDD')
			}
			// const categoryList = this.categoryList.map((res) => {
			// 	res;
			// })
			const categoryList = this.categoryList
			const groupList = this.groupList
			let pattern = /"(.*?)/;
			let exactPhraseTextUsed = '';
			if( this.exactphraseText.match(pattern) ){
				// already includes double quotes, no need to add here
				exactPhraseTextUsed =  this.exactphraseText
			}else{
				exactPhraseTextUsed = this.exactphraseText ?  '"' +  this.exactphraseText + '"' : ''
			}
            let search = this.search;
			if(exactPhraseTextUsed && exactPhraseTextUsed.length > 2 ){
				// if advnce search apppled , no need to add search text in search data here, so empty
				search = '';
			}
			// if advnce search apppled , no need to add search text in search data here
			const obj = {
				searchData: this.allOfTheseText,
			//	searchData: '',
				page: 1,
				pageSize: 50,
				filter: {
					categoryList: categoryList,
					groupList: groupList,
					actList: [],
					sectionList: [],
					courtList: [],
					benchList: [],
					yearOfPublicationList: [],
					journalList: [],
					decisionDateFrom: dateFrom,
					decisionDateTo: dateTo,
					volume: null,
					pageNo: null,
					subjectList: this.selectedSubjectValue,
				},
				subjectLabelArr: this.selectedSubjectArrList,
				sortorder: 1,
				sortby: 'relevance',
				//isExcusSearch: this.isExcusSearch,
			}
			if(search){
				obj['isAdvSearch'] = false;
				obj['advanceSearch'] = {}
			}else{
				obj['advanceSearch'] = {
					exactSearch:  exactPhraseTextUsed,
					notIncludeSearch: this.noneOfTheseText,
					anyOfSearch: this.anyOfTheseText,
				};
			}
			this.headerService.callAdvanceSearchApi(obj).subscribe(
				(res) => {
					this.isAdvanceSearchCalled = true
					if (res?.ResponseType == 'SUCCESS') {
						this.AdvModuleList = []
						this.AdvModuleList = res?.Data?.filter[0]?.list?.items.map((data) => {
							return { value: data.value, label: data.label }
						})
						this.AdvDocumentType = res?.Data?.filter[1]?.list?.items.map((data) => {
							return { value: data.value, label: data.label }
						})
						if(this.AdvModuleList && this.AdvModuleList.length){
						}else{
							this.categoryList = [];
						}
						if(this.AdvDocumentType && this.AdvDocumentType.length){
						}else{
							this.groupList = [];
						}
						if (navigationFlag) {
							if (this.activeItems == 'AP' || this.activeItems == 'ES') {
								this.displayBasic = false
								this.appUtilityService.setDataOnLocalStorage('advanceSearchCall', 'Y')
								let queryParams = { itemType: 'advanceSearch' }
								if (this.exactphraseText) {
									this.exactphraseText = this.exactphraseText.replace(/^"(.*)"$/, '$1');
									let pattern = /"(.*?)/;
									if( this.exactphraseText.match(pattern) ){
										// already includes double quotes, no need to add here
										queryParams['exactPhrase'] =  this.exactphraseText
									}else{
										queryParams['exactPhrase'] =  '"' +  this.exactphraseText + '"'
									}
								}
									if (this.allOfTheseText) {
										queryParams['searchData'] = this.allOfTheseText
									}
								
								if (this.noneOfTheseText) {
									queryParams['noneOfTheseText'] = this.noneOfTheseText
								}
								if (this.anyOfTheseText) {
									queryParams['anyOfTheseText'] = this.anyOfTheseText
								}
								this.router.navigate(['/search'], { queryParams })
							} else {
								// this._homeService.searchTextForCurrentPage.next(this.search);
								this.router.navigate([])
							}
							setTimeout(() => {
								obj['filter']['decisionDateFrom'] = this.dateFrom
								obj['filter']['decisionDateTo'] = this.dateTo
								obj['selectedSubjectObject'] = { items: this.selectedSubjectArr, label: '', value: 1 }
								this.appUtilityService.setAdvSearchRes({ response: res, payload: obj })
							}, 500)
						}
					}
				},
				(error) => { }
			)
		}
	}

	resetAdvSearchFilters() {
		this.dateFrom = ''
		this.dateTo = ''
		this.search = ''
		this.exactphraseText = ''
		this.anyOfTheseText = ''
		this.noneOfTheseText = ''
		this.allOfTheseText = ''
		this.categoryList = []
		this.groupList = []
		this.selectedSubjectArrList = []
		this.selectedSubjectValue = []
		this.selectedSubjectArr = [];
		(document.getElementById('header-search') as any).value = '';
	// this.closeAdvancedFilters();
	}

	searchSubject(event) {
		this.baseService.subjectSearchText = event.query
		this.baseService.getCentaxSubjectFilter('getSubject')
		this.baseService.getSubjectFilterList().subscribe((response: any) => {
			if (response && response.length > 0) {
				this.centaxSubjectListData = response[0].list.items
			} else {
				this.centaxSubjectListData = []
			}
		})
	}
	selectedSubject() {
		; (this.selectedSubjectArrList = this.selectedSubjectArr.map((res) => res.label)),
			(this.selectedSubjectValue = this.selectedSubjectArr.map((res) => res.value)),
			(this.showSubjectEdit = false)
	}

	unselect() {
		; (this.selectedSubjectArrList = this.selectedSubjectArr.map((res) => res.label)), 
		(this.selectedSubjectValue = this.selectedSubjectArr.map((res) => res.value)),
		(this.showSubjectEdit = false)
	}

	showEditMode() {
		this.showSubjectEdit = true
	}

	hideEditMode() {
		this.showSubjectEdit = false
	}

	manageMobileSidebar(event): void {
		if (this.isMobileView) {
			document.body.classList.remove('scroll-off')
			this.visibleSidebar1 = event
			this._homeService.sideBarCollapse.next(false)
			this.appUtilityService.setShowFilter(false)
		}
	}

	ngOnDestroy() {
		this.alive = false
		this.showGlobalSearch$ && this.showGlobalSearch$.unsubscribe()
		this.showSearchSuggestion$ && this.showSearchSuggestion$.unsubscribe()
	}

	closeAdvancedFilters() {
		if (!this.search && !this.exactphraseText && !this.anyOfTheseText && !this.noneOfTheseText) {
			this.displayBasic = false
			if (this.isAdvanceSearchCalled) {
				this.router.navigate(['/'])
			}
			this.isAdvanceSearchCalled = false
		} else {
			this.displayBasic = false
		}
	}

	manageUploadAndSearchPopup(state: any): void {
		this.showUploadAndSearchMenu = state
		if (state === 'hide') {
			this.search = ''
		}
	}

	removeChip() {
		this.showSubjectEdit = true
		this.unselect()
	}

	startTour() {
		if (this.isMobileView) {
			return
		}
		this.openForTut = true
		this.joyrideService
			.startTour(
				{
					steps: ['firstStep', 'secondStep'],
					customTexts: {
						done: 'Done',
						prev: 'Back',
						next: 'Next',
					},
					showCounter: true,
					waitingTime: 100,
					stepDefaultPosition: 'right',
				} // Your steps order
			)
			.subscribe(
				(step) => {
					setTimeout(() => {
						$(window).scrollTop(0)
					}, 1)
				},
				(error) => {
					console.log('Joyride error section')
					this.joyrideService.closeTour()
				},
				() => {
					this.openForTut = false
					this.activeItems = 'AP'
					this.appUtilityService.setDataOnLocalStorage(LocalStorageDataModel.SEARCH_MENU, 'AP')
					console.log('tour finished forcefully or completed')
				}
			)
	}

	searchTypeChange(type) {
		if (type == 'AP') {
			this.appUtilityService.isExcusSearch = false
			this.isExcusSearch = false
			this.activeItems = type
			this.showSearch = true
			this.appUtilityService.setDataOnLocalStorage(LocalStorageDataModel.SEARCH_MENU, type)
			this.navigateWithSearchSuggestionsOnEnter(type)
			this.showMobileSearchMenu = !this.showMobileSearchMenu
		} else if (type == 'ES') {
			this.appUtilityService.isExcusSearch = true
			this.isExcusSearch = true
			this.activeItems = type
			this.showSearch = true
			this.appUtilityService.setDataOnLocalStorage(LocalStorageDataModel.SEARCH_MENU, type)
			this.navigateWithSearchSuggestionsOnEnter(type)
			this.showMobileSearchMenu = !this.showMobileSearchMenu
		}
	}

	tourNextStep() {
		this.activeItems = 'ES'
		this.appUtilityService.setDataOnLocalStorage(LocalStorageDataModel.SEARCH_MENU, this.activeItems)
	}

	tourPrevStep() {
		this.activeItems = 'AP'
		this.appUtilityService.setDataOnLocalStorage(LocalStorageDataModel.SEARCH_MENU, this.activeItems)
	}

	get resetBtnStatus() {
		if (
			this.dateFrom == '' &&
			this.dateTo == '' &&
			this.search == '' &&
			this.exactphraseText == '' &&
			this.anyOfTheseText == '' &&
			this.noneOfTheseText == '' &&
			this.categoryList.length == 0 &&
			this.groupList.length == 0 &&
			this.selectedSubjectArrList.length == 0 &&
			this.selectedSubjectValue.length == 0 &&
			this.selectedSubjectArr.length == 0 && 
			this.allOfTheseText == ''
		) {
			return true
		} else {
			return false
		}
	}

	get advanceSearchHighlight() {
		if (this.exactphraseText == '' && this.anyOfTheseText == '' && this.noneOfTheseText == '') {
			return false
		} else {
			return true
		}
	}

	get disableFilters() {
		if (
			this.allOfTheseText == '' &&
			this.exactphraseText == '' &&
			this.anyOfTheseText == '' &&
			this.noneOfTheseText == ''
		) {
			return true
		} else {
			return false
		}
	}

	onChange(event) {
		console.log('ths.search', event)
		this.baseService.setsearchTextHeader(event)
	}

	onPricingSubscription(): void {
		this._homeService.showPricing.subscribe((value) => {
			this.isShowPricing = value
		})
	}

	onDropdownPageChange(): void {
		this._homeService.pagechangeValue.subscribe((value) => {
			this.showPagechangeValue = value
		})
	}

	checkLogin() {
		this.appUtilityService.checkForLogin.pipe(takeWhile((_) => this.alive)).subscribe((res) => {
			console.log('check for login', res)
			if (res) {
				this.isLoggedIn = this.appUtilityService.isLoggedIn()
				this.email = this.appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.EMAIL)
				this.userName = this.appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.USER_NAME)
				this.appUtilityService.checkForLogin.next(null)
				this.commonAlertService.successToast('User login successfully')
				this.router.navigate(['/latest-news-updates/all'])
			}
		})
	}

	onClickCloseResearchRenewalStrip(){
		this.isShowResearchRenewalStip = false;
		if(this.noOfDaysForExpiryLeft > 0 ){
		this.showResearchRenewalNotificationPopup = true;
		this.researchRenewalStipEvent.emit(this.isShowResearchRenewalStip);
		}else{
			this.onClickResearchRenewalNotificationStrip();
		}
	}

   researchRenewalNotificationPopupReminderCalculation(){
    if(this.researchRenewalData && this.researchRenewalData.renewProductDetails && this.researchRenewalData.renewProductDetails.length > 0){
		this.getResearchRenewalNotificationStripData();
		let researchRenewalPlanToConsider = this.researchRenewalData.renewProductDetails[this.researchRenewalData.renewProductDetails.length-1];
		console.log("researchRenewalPlanToConsider",researchRenewalPlanToConsider)
		if(researchRenewalPlanToConsider && researchRenewalPlanToConsider.PlanId){
			let  currentDate:any  = moment(new Date().setHours(0, 0, 0, 0));
		let researchRenewalPlanToConsiderExpiryDate = moment(new Date(researchRenewalPlanToConsider.ExpiryDate).setHours(0, 0, 0, 0));
		this.noOfDaysForExpiryLeft = researchRenewalPlanToConsiderExpiryDate.diff(currentDate, 'days');
		console.log("noOfDaysForExpiryLeft",this.noOfDaysForExpiryLeft)
		if(this.noOfDaysForExpiryLeft > 1 ){
			let researchRenewalNotificationExtractedNumber = 0;
                 if(this.noOfDaysForExpiryLeft > 15){
					researchRenewalNotificationExtractedNumber = 3;
					this.calculatedResearchRenewalNotificationPopupData = this.researchRenewalNotificationPopupData.slice(0,researchRenewalNotificationExtractedNumber)
				 }else if(this.noOfDaysForExpiryLeft > 7){
					researchRenewalNotificationExtractedNumber = 2;
					this.calculatedResearchRenewalNotificationPopupData = this.researchRenewalNotificationPopupData.slice(0,researchRenewalNotificationExtractedNumber)
				 }else if(this.noOfDaysForExpiryLeft > 1){
					researchRenewalNotificationExtractedNumber = 1;
					this.calculatedResearchRenewalNotificationPopupData = this.researchRenewalNotificationPopupData.slice(0,researchRenewalNotificationExtractedNumber)
				 }else{
					researchRenewalNotificationExtractedNumber = 1;
					this.calculatedResearchRenewalNotificationPopupData = this.researchRenewalNotificationPopupData.slice(0,researchRenewalNotificationExtractedNumber)
				 }
				 this.calculatedResearchRenewalNotificationPopupData.push({days:this.noOfDaysForExpiryLeft});
		}else if(this.noOfDaysForExpiryLeft == 1){
			this.calculatedResearchRenewalNotificationPopupData.push({days:this.noOfDaysForExpiryLeft});
			console.log("calulaed pop up data", this.calculatedResearchRenewalNotificationPopupData)
		}else{

		}
		}
	}
   }

   getResearchRenewalNotificationStripData(){
	if(this.researchRenewalData.renewProductDetails && this.researchRenewalData.renewProductDetails.length){
			this.selectResearchRenewalNotificationPopupDataForStrip.push(this.researchRenewalData.renewProductDetails[0]);
	}
   }

    compareDates = (d1, d2) => {
	let date1 = moment(d1).format('L');
	let date2 = moment(d2).format('L');
	if (date1 < date2) {
		return false;
		// get all data also within 1 year of of first product
	} else if (date1 > date2 + 365) {
       return true;
	} else {
		return true;
	}
  };


	callRenewalAlert(){
		this.headerService.getRenewalAlert().subscribe((response: any) => {
                  if(response && response.ResponseType == ApiStatus.SUCCESS){
                        this.researchRenewalData = response.Data;
						this.showResearchRenewalStip();
				  }
		});
	}

	showResearchRenewalStip(){
		if(this.researchRenewalData.isShow && this.researchRenewalData.renewProductDetails && this.researchRenewalData.renewProductDetails.length > 0){
		this.isShowResearchRenewalStip = true;
        this.researchRenewalStipEvent.emit(this.isShowResearchRenewalStip);
		this.researchRenewalNotificationPopupReminderCalculation();
		}
	}

	onClickResearchRenewalStrip(){
	// 	let ProductList = [];
	// 	let payload = {
	// 		cartSource: CheckoutFrom.BUY_NOW,
	// 		ProductList: ProductList,
	// 		prodID: '0',
	// 		qTy: '0',
	// 	}
	// 	if(this.selectResearchRenewalNotificationPopupDataForStrip && this.selectResearchRenewalNotificationPopupDataForStrip.length > 1){
	// 		// buy now multiple product
	// 	for (let product of this.selectResearchRenewalNotificationPopupDataForStrip) {
	// 			ProductList.push(Number(product.PlanId));
	// 	}
	// 	this.addProductToCart(payload);
	// }else{
	// 	// buy now single product
	// 	console.log("data elected tp checkout", this.selectResearchRenewalNotificationPopupDataForStrip)
	// 	ProductList.push(Number(this.selectResearchRenewalNotificationPopupDataForStrip[0].PlanId));
	// 	this.addProductToCart(payload);
	// }
	this.onClickResearchRenewalNotificationStrip();
	setTimeout(() => {
		this.router.navigate(['/pricing']);
		this.appUtilityService.setDataOnLocalStorageWithExpiry(LocalStorageDataModel.RENEW_RESEARCH_PLAN, 1, 24* 60 * 60 * 1000 ) //time here in seconds
	}, 50);
	}

	onClickResearchRenewalNotificationStrip(){
	this.isShowResearchRenewalStip = false;
	this.researchRenewalStipEvent.emit(this.isShowResearchRenewalStip);
	}


addProductToCart(payload){
	this.appUtilityService.showLoader = true
	this.pricingService.addMultipleProductToCart(payload).subscribe(
		(response) => {
			this.appUtilityService.showLoader = false
			if (response && response.ResponseType === ApiStatus.SUCCESS) {
				// this.commonAlertService.successToast(response.StatusMsg);
				this.router.navigate(['store', 'checkout']);
			}else if (response.ResponseType === ApiStatus.PLAN_ALREADY_ACTIVATED) {
				this.commonAlertService.errorToast(response.StatusMsg)
			}
		},
		(error) => {
			this.appUtilityService.showLoader = false
		}
	);
}

	hideResearchRenewalNotificationPopup(){
		this.onClickOkayResearchRenewalNotificationPopup()
	}

	onClickOkayResearchRenewalNotificationPopup() {
		let data = {
		  "PlanId": this.getResearchRenewalProductIds(),
		  "Days": this.noOfDaysSelected
		}
		this.headerService.postRenewalAlert(data).subscribe((response: any) => {
		  if (response && response.ResponseType == ApiStatus.SUCCESS) {
			  this.showResearchRenewalNotificationPopup = false;
			  this.researchRenewalStipEvent.emit(this.isShowResearchRenewalStip);
		  }
		});
	  }

	  getResearchRenewalProductIds() {
		let dataArray = this.researchRenewalData.renewProductDetails.map((data) => data.PlanId);
		return dataArray.join(',');
	  }

	  closeResearchRenewalNotificationPopup() {
		this.showResearchRenewalNotificationPopup = false;
	  }


	  onChangeResearchRenewalNotificationDays(days){
			this.noOfDaysSelected = days;
	  }



	closePopupHeader() {
		this.showPopupHeader = false;
		this.showBrowserAlert = false;
	}


	noThanksNotification() {
		this.isNoThanks = true;
		this.showBrowserAlert = false;
		let cookieValue = "yes";
		this.appUtilityService.setCookie(LocalStorageDataModel.NOTIFICATION_ALERT, cookieValue, 3600)
	}
	manageNotificationClose() {
		this.isNoThanks = false;
		this.showBrowserAlert = false;
	}

	showNotificationAlert(isFrominit?: boolean) {
		// if (this.isMobileView) {
		// 	return;
		// }
		if (!this.OneSignal.isPushNotificationsSupported()) {
			return;
		}
		this.OneSignal.push(() => {
			/*Check If Subscribed */
			this.OneSignal.isPushNotificationsEnabled((isEnabled) => {
				if (isEnabled) {
					if (isFrominit) {
						this.showBrowserAlert = false;
					}
					this.isBrowserAlertActive = true;
				} else {
					this.isNoThanks = false;
					this.isBrowserAlertActive = false;
					if (!this.checkNotificationCookie()) {
						this.showBrowserAlert = true;
					}
				}
			}, (error) => {
				console.log('Push permission not granted');
			});
			if (!isFrominit) {
				this.showBrowserAlert = true;
			}
		});
	}


	checkNotificationCookie(): boolean {
		let cookieValue = this.appUtilityService.getCookie(LocalStorageDataModel.NOTIFICATION_ALERT);
		if (cookieValue !== "") return true;
		else return false;
	}


	manageNotificationAlert(isSubscribed?: boolean) {
		// if (this.isMobileView) {
		// 	return;
		// }
		if (!isSubscribed) {
			let oneSignalId;
			this.OneSignal.push(() => {
				this.OneSignal.registerForPushNotifications();
			});
			this.OneSignal.getUserId().then((userId) => {
				oneSignalId = userId;
				if (oneSignalId) {
					this.OneSignal.push(() => {
						this.OneSignal.setSubscription(true);
					});
				}

			});
			this.isBrowserAlertActive = true;
		}
		else {
			this.OneSignal.push(() => {
				this.OneSignal.setSubscription(false);
			});
			this.isBrowserAlertActive = false;
		}
		this.showBrowserAlert = false;
	}

}
