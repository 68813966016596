import { Injectable } from '@angular/core'
import { CentaxApiList, PricingApiList } from '@App/constant/centax-api.constant'
import { USER_PROFILE_API } from '@App/modules/component-modules/constant/api.constant'
import { HttpHelperService } from '@App/services/http-helper.service'
import { AppUtilityService } from '@App/utility/app-utlility.service'

@Injectable()
export class PricingService {
	constructor(protected httpHelperService: HttpHelperService, protected appUtilityService: AppUtilityService) {}

	getSingleUserPricing() {
		let url = this.appUtilityService.getApiUrl(PricingApiList.SINGLE_USER_PRICING)
		return this.httpHelperService.get(url, {}, true)
	}

	getFreeTrial(data) {
		let url = this.appUtilityService.getApiUrl(PricingApiList.FREE_TRIAL)
		let payload = data;
		payload['activatetrial'] = '1';
		return this.httpHelperService.post(url, payload, true)
	}

	addMultipleProductToCart(payload: Object) {
		let url = this.appUtilityService.getApiUrl(PricingApiList.ADD_MULTIPLE_PRODUCT_TO_CART)
		return this.httpHelperService.post(url, payload, true)
	}

	enterpriseContact(payload: Object ) {
	//	let url = "http://localhost:3010/centax/api/v1/enterpriseContact"
		 let url = this.appUtilityService.getApiUrl(PricingApiList.ENTERPRISE_CONTACT)
		return this.httpHelperService.post(url, payload, true)
	}

	getETDSFAQs(data: Object) {
		let url = this.appUtilityService.getApiUrl(CentaxApiList.GET_FAQS, 'crm')
		return this.httpHelperService.post(url, data, false)
	}

	getUserProfileData(params) {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.GET_USER_PROFILE_DATA)
		return this.httpHelperService.get(url, params, true)
	}
	
	sendOTP(data: Object) {
		//let url = "http://localhost:3010/centax/api/v1/enterpriseContact/sendOtp"
		 let url = this.appUtilityService.getApiUrl(PricingApiList.SEND_OTP)
		return this.httpHelperService.post(url, data, false)
	}

	verifyOTP(data: Object) {
		//let url = "http://localhost:3010/centax/api/v1/enterpriseContact/verifyOtp";
		 let url = this.appUtilityService.getApiUrl(PricingApiList.VERIFY_OTP)
		return this.httpHelperService.post(url, data, false)
	}

	createLead(payload: Object ) {
			 let url = this.appUtilityService.getApiUrl(PricingApiList.CREATE_LEAD)
			return this.httpHelperService.post(url, payload, true)
		}

		postActivateAccessKey(data:Object) {
			let url = this.appUtilityService.getApiUrl(CentaxApiList.ACTIVATE_ACCESS_KEY)
			return this.httpHelperService.post(url, data, true)
		}
	
}
